<template>
<loader v-bind="{ loading }">
  <div>
    <columns>
      <column>
        <page-heading
          heading="New Location"
        />
      </column>
      <column class="is-narrow">
        <router-link :to="{
          name: 'locations'
        }">Back</router-link>
      </column>
    </columns>
    <columns>
      <column>
        <text-input
          :error="$root.errors.name"
          classes="is-medium is-rounded"
          required
          :value="location.name"
          @input="updateName">
          Name
        </text-input>
      </column>
      <column>
        <text-input
          :error="$root.errors.legal_entity"
          classes="is-medium is-rounded"
          :value="location.legal_entity"
          @input="updateLegalEntity">
          Legal Name <small class="has-text-weight-light">(Optional)</small>
        </text-input>
      </column>
      <column>
        <text-input
          :error="$root.errors.reference"
          classes="is-medium is-rounded"
          :value="location.reference"
          @input="updateReference">
          Reference <small class="has-text-weight-light">(Optional)</small>
        </text-input>
      </column>
      <column class="is-2">
        <data-selector
            required
            classes="is-medium is-rounded"
            searchable
            :error="$root.errors.type"
            :items="location_types"
            :value="location.type"
            @input="updateType">
            Type
        </data-selector>
      </column>
      <column>
        <data-selector
          :items="customer_list"
          label-key="name"
          value-key="uuid"
          classes="is-medium is-rounded"
          required
          :error="$root.errors.customer_uuid"
          :value="location.customer_uuid"
          @input="updateCustomer">
          Customer
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <data-selector
          tag-class="is-medium is-rounded"
          classes="is-medium is-rounded"
          delete-button-class="is-medium is-rounded is-danger"
          description="A list of branches that can work with this customer."
          multiple
          searchable
          required
          :items="branch_list"
          value-key="id"
          label-key="name"
          :value="location.branches"
          :error="$root.errors.branches"
          @input="updateBranches">
          Branches
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <physical-address />
      </column>
    </columns>
    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="saving"
          @submit="save">
          Save
        </submit-button>
      </column>
    </columns>
  </div>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {

  data: () => ({
    saving: false,
    loading: true,
  }),

  async beforeCreate() {
    await this.$store.dispatch('customer/loadCustomerList')
    await this.$store.dispatch('customer/loadLocationTypes')
    await this.$store.dispatch('branch/loadList')
    this.loading = false
  },

  created() {
    this.clearLocation()
  },

  beforeDestroy() {
    this.clearLocation()
  },

  methods: {
    ...mapMutations('location', [
        'updateName', 
        'updateLegalEntity', 
        'updateCustomer',
        'updateAddress',
        'updateType',
        'updateBranches',
        'clearLocation',
        'updateReference'
    ]),
    save() {
      this.saving = true;
      this.$store
        .dispatch('location/create')
        .then(() => {
          this.saving = false
          this.$toast.success('saved')
          this.$router.push({
            name: 'locations'
          })
        })
        .catch(() => {
          this.saving = false;
        })
    },
    setAddress(address) {
        this.updateAddress(address)
    },
  },

  computed: {
    ...mapGetters('location', ['location']),
    ...mapGetters('customer', ['customer_list', 'location_types']),
    ...mapGetters('branch', [
      'branch_list'
    ]),
  },
};
</script>